import './Card.scss';
import React from 'react';
import classNames from 'classnames';
import Typography from '@components/Typography';

interface CardProps {
  className?: string;
  title: string;
  icon?: string;
  description: string;
  gradientColor?: string;
  backgroundImage?: string;
}

const Card: React.FC<CardProps> = (props) => {
  const { title, className, description, children, backgroundImage, icon, gradientColor } = props;

  return (
    <div className={classNames('TDB-OpenSourcePage__card', className)}>
      <div className="TDB-OpenSourcePage__card__inner">
        <div className="TDB-OpenSourcePage__card__background-wrapper">
          <div className="TDB-OpenSourcePage__card__background">
            <div className="TDB-OpenSourcePage__card__background__content">
              <div
                className={classNames('TDB-OpenSourcePage__card__blur', {
                  'TDB-OpenSourcePage__card__blur--has-bg-image': !!backgroundImage,
                })}
              >
                <div className="TDB-OpenSourcePage__card__gradient-1" style={{ background: `${gradientColor}` }} />
                <div className="TDB-OpenSourcePage__card__gradient-2" />
                <div className="TDB-OpenSourcePage__card__gradient-3" />
                <div className="TDB-OpenSourcePage__card__gradient-4" />
              </div>
              {backgroundImage && (
                <div className="TDB-OpenSourcePage__card__background-image" style={{ backgroundImage: `url(${backgroundImage})` }} />
              )}
            </div>
          </div>
        </div>
        <div className="TDB-OpenSourcePage__card__content">
          <div className="TDB-OpenSourcePage__card__header">
            <Typography as="p" className="TDB-OpenSourcePage__card__title" fontSize="heading-3" fontWeight="bold">
              {title}
            </Typography>
            {icon && <img className="TDB-OpenSourcePage__card__icon" src={icon} alt="" />}
          </div>
          <Typography
            as="p"
            className="TDB-OpenSourcePage__card__description"
            fontSize="subheading-2"
            fontWeight="medium"
            color="neutral-100"
          >
            {description}
          </Typography>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Card;
