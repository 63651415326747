import './LifeSciences.scss';
import React from 'react';
import links from '@constants/links';
import Title from '../components/Title';
import Card from '../components/Card';
import Buttons from '../components/Card/components/Buttons';
import GithubIcon from '../../assets/github.svg';
import CIcon from './assets/c.svg';
import CPlusPlusIcon from './assets/cplusplus.svg';
import PythonIcon from './assets/python.svg';
import JavaIcon from './assets/java.svg';
import SeuratIcon from './assets/seurat.svg';
import BioconductorIcon from './assets/bioconductor.svg';
import ScanpyIcon from './assets/scanpy.svg';
import OmeIcon from './assets/ome.svg';
import BioimagingBackground from './assets/texture_bioimaging.png';

const LifeSciences: React.FC = () => {
  return (
    <div className="TDB-OpenSourcePage__LifeSciences">
      <Title>Life Sciences</Title>
      <Card
        gradientColor="#0bdefb"
        title="TileDB-VCF"
        description="An efficient and scalable genomic variant store, which allows you to ingest large collections of VCF/BCF files. It is optimized for cloud object stores and solves the N+1 problem for growing datasets."
      >
        <div className="TDB-OpenSourcePage__LifeSciences__vcf__icons">
          <img src={CIcon} alt="C" />
          <img src={CPlusPlusIcon} alt="C++" />
          <img src={PythonIcon} alt="python" />
          <img src={JavaIcon} alt="java" />
        </div>
        <Buttons items={[{ text: 'View on Github', icon: GithubIcon, link: links.github.tiledbVCF }]} />
      </Card>

      <Card
        gradientColor="rgba(255, 2, 15, 0.5)"
        title="TileDB-SOMA"
        description="The implementation of the single-cell SOMA spec, co-developed by TileDB and CZI. It allows you to store large collections of single-cell experiments on cloud object stores, offering rapid slicing directly from the cloud without tedious downloads."
      >
        <div className="TDB-OpenSourcePage__LifeSciences__soma__icons">
          <img src={SeuratIcon} alt="seurat" />
          <img src={BioconductorIcon} alt="bioconductor" />
          <img src={ScanpyIcon} alt="scanpy" />
        </div>
        <Buttons
          items={[
            { text: 'SOMA Spec', icon: GithubIcon, link: links.github.tiledbSOMASpec },
            { text: 'TileDB-SOMA', icon: GithubIcon, link: links.github.tiledbSOMA },
          ]}
        />
      </Card>

      <Card
        gradientColor="#0BDEFB"
        backgroundImage={BioimagingBackground}
        title="TileDB-BioImaging"
        description="Convert images stored in popular formats like OME-Zarr, OME-Tiff and Open-Slide to groups of TileDB arrays. Efficiently query via a TileDB-powered API and export images to OME."
      >
        <div className="TDB-OpenSourcePage__LifeSciences__bioimaging__icons">
          <img src={OmeIcon} alt="ome" />
        </div>
        <Buttons items={[{ text: 'View on Github', icon: GithubIcon, link: links.github.tiledbBioimaging }]} />
      </Card>
    </div>
  );
};

export default LifeSciences;
