import './Hero.scss';
import React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';

const Hero: React.FC = () => {
  return (
    <div className="TDB-OpenSourcePage__HeroWrapper">
      <Container>
        <div className="TDB-OpenSourcePage__Hero">
          <Typography className="TDB-OpenSourcePage__Hero__Title" as="h1" fontSize="overline" fontWeight="bold" color="neutral-main">
            TILEDB <span className="TDB-OpenSourcePage__Hero__Title__Emphasis">OPEN-SOURCE</span>
          </Typography>
          <Typography
            className="TDB-OpenSourcePage__Hero__Subtitle"
            as="h2"
            fontSize="display-1"
            fontWeight="bold"
            color="neutral-main"
            align="left"
            style={{ width: '100%' }}
          >
            <div style={{ maxWidth: 1000 }}>An open-source ecosystem for complex data storage and analysis</div>
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
