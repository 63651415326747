import links from './links';

const openSourcePages = [
  {
    text: 'Array Storage',
    path: links.openSource.arrayStorage,
  },
  {
    text: 'Life Sciences',
    path: links.openSource.lifeSciences,
  },
  {
    text: 'Geospatial',
    path: links.openSource.geospatial,
  },
  {
    text: 'ML & Data Science',
    path: links.openSource.machineLearning,
  },
  {
    text: 'Distributed Compute',
    path: links.openSource.distributedCompute,
  },
];

export default openSourcePages;
