import './Title.scss';
import React from 'react';
import Typography from '@components/Typography';

const Title: React.FC = (props) => {
  const { children } = props;

  return (
    <Typography as="h2" className="TDB-OpenSourcePage__title" fontSize="heading-1" color="neutral-main" fontWeight="bold">
      {children}
    </Typography>
  );
};

export default Title;
