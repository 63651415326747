import classNames from 'classnames';
import './Menu.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from '@reach/router';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Typography from '@components/Typography';
import Container from '@components/Container';
import openSourcePages from '@constants/openSourcePages';

interface MenuItemProps {
  href: string;
  className?: string;
  onClick?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { children, href, onClick, className } = props;
  const { pathname } = useLocation();
  const active = pathname.startsWith(href);

  return (
    <Typography
      className={classNames('TDB-OpenSourcePage__menu__item', className, { 'TDB-OpenSourcePage__menu__item--active': active })}
      onClick={onClick}
      color="neutral-300"
      fontSize="body-lg"
      fontWeight="semi-bold"
      to={href}
    >
      {children}
    </Typography>
  );
};

const Menu: React.FC = () => {
  const { pathname } = useLocation();
  const scrollElementRef = useRef<HTMLDivElement | undefined>();
  const [scrollTop, setScrolltop] = useState<number | undefined>(undefined);
  const [submenuActive, setSubmenuActive] = useState(false);
  useScrollPosition(
    ({ currPos }) => {
      setScrolltop(currPos.y);
    },
    [],
    scrollElementRef
  );

  const isFixed = scrollTop && scrollTop <= 0;

  useEffect(() => {
    if (typeof window !== undefined) {
      // save initial position of element to state
      // this runs before useScrollPosition runs
      // and ensures that the scrollTop is not undefined on initial render
      const t = scrollElementRef.current?.getBoundingClientRect().top;
      setScrolltop(t);

      const offsetTop = scrollElementRef.current?.offsetTop || 0;
      // if window scroll is under the menu, scroll it back to the menu
      if (window.scrollY > offsetTop) {
        window.scrollTo(0, offsetTop);
      }
    }
  }, []);

  return (
    <div className="TDB-OpenSourcePage__menu-placeholder" ref={scrollElementRef as any}>
      <div
        className={classNames('TDB-OpenSourcePage__menu-wrapper TDB-OpenSourcePage__menu-wrapper--desktop', {
          'TDB-OpenSourcePage__menu-wrapper--fixed': isFixed,
        })}
      >
        <Container>
          <div className="TDB-OpenSourcePage__menu TDB-OpenSourcePage__menu--desktop">
            {openSourcePages.map((item) => (
              <MenuItem key={item.path} href={item.path}>
                {item.text}
              </MenuItem>
            ))}
          </div>
        </Container>
      </div>
      <div
        className={classNames('TDB-OpenSourcePage__menu-wrapper TDB-OpenSourcePage__menu-wrapper--mobile', {
          'TDB-OpenSourcePage__menu-wrapper--fixed': isFixed,
        })}
      >
        <div className="TDB-OpenSourcePage__mobile-menu">
          {openSourcePages
            .filter((it) => pathname.startsWith(it.path))
            .map((it) => (
              <MenuItem
                className="TDB-OpenSourcePage__mobile-menu__active-item"
                href={it.path}
                onClick={() => {
                  setSubmenuActive(!submenuActive);
                }}
              >
                <span>{it.text}</span>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={classNames('TDB-OpenSourcePage__mobile-menu__active-item__caret', {
                    'TDB-OpenSourcePage__mobile-menu__active-item__caret--active': submenuActive,
                  })}
                >
                  <g clipPath="url(#clip0_840_7184)">
                    <path
                      d="M16.5 12.5C16.5005 12.7526 16.4503 13.0027 16.3526 13.2357C16.2548 13.4686 16.1114 13.6796 15.9308 13.8562L9.03957 20.6548C8.81397 20.8762 8.51044 21.0002 8.19434 21C7.87823 20.9998 7.57485 20.8755 7.34951 20.6538C7.23874 20.5448 7.15078 20.4149 7.09077 20.2716C7.03077 20.1282 6.99991 19.9744 7 19.819C7.00009 19.6636 7.03114 19.5098 7.09132 19.3665C7.1515 19.2233 7.23961 19.0934 7.35051 18.9846L13.9238 12.5L7.35051 6.0154C7.23966 5.90653 7.1516 5.77668 7.09145 5.63342C7.0313 5.49017 7.00028 5.33637 7.00018 5.181C7.00009 5.02563 7.03093 4.87179 7.09091 4.72847C7.15088 4.58514 7.23879 4.45518 7.34951 4.34618C7.57484 4.12449 7.87823 4.00017 8.19433 3.99998C8.51043 3.9998 8.81396 4.12376 9.03955 4.34518L15.9308 11.1436C16.1114 11.3202 16.2548 11.5313 16.3525 11.7642C16.4503 11.9972 16.5004 12.2474 16.5 12.5Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_840_7184">
                      <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                  </defs>
                </svg>
              </MenuItem>
            ))}
          {submenuActive && (
            <div className="TDB-OpenSourcePage__mobile-menu__submenu">
              {openSourcePages.map((it) => (
                <MenuItem
                  className={classNames('TDB-OpenSourcePage__mobile-menu__item', {
                    'TDB-OpenSourcePage__mobile-menu__item--active': it.path === pathname,
                  })}
                  href={it.path}
                  onClick={() => {
                    setSubmenuActive(!submenuActive);
                  }}
                >
                  {it.text}
                </MenuItem>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
