import './OpenSourcePageWrapper.scss';
import React from 'react';
import IndexLayout from '@layouts/index';
import Header from '@components/Header';
import Footer from '@components/Footer';
import Container from '@components/Container';
import thumb from '../assets/TileDB_Open-source-page_thumb.png';
import Hero from '../Hero';
import Background from '../Background';
import Menu from '../Menu';

const OpenSourcePage: React.FC = (props) => {
  const { children } = props;

  return (
    <IndexLayout
      pageName="open-source"
      helmet={{
        title: 'TileDB Open-Source',
        description: 'An open-source ecosystem for any data modality, simple or complex.',
        shareImage: {
          url: thumb,
          width: 2000,
          height: 1050,
        },
      }}
    >
      <Background />
      <div className="page--open-source__content">
        <Header variant="transparent" />
        <main className="main">
          <Hero />
          <Menu />
          <Container>{children}</Container>
        </main>
        <Footer variant="transparent-dark" />
      </div>
    </IndexLayout>
  );
};

export default OpenSourcePage;
