import './Buttons.scss';
import React from 'react';
import classNames from 'classnames';
import Typography from '@components/Typography';

interface ButtonType {
  icon: string;
  text: string;
  link: string;
}

interface ButtonsProps {
  items: ButtonType[];
  className?: string;
}

const Buttons: React.FC<ButtonsProps> = (props) => {
  const { items = [], className } = props;

  return (
    <div className={classNames('TDB-OpenSourcePage__card__buttons', className)}>
      {items.map((item) => (
        <a className="TDB-OpenSourcePage__card__button" href={item.link} target="_blank" rel="noreferrer">
          <img className="TDB-OpenSourcePage__card__button__icon" src={item.icon} alt={item.text} />
          <Typography fontSize="body" fontWeight="semi-bold" color="neutral-main">
            {item.text}
          </Typography>
        </a>
      ))}
    </div>
  );
};

export default Buttons;
