import React from 'react';
import './Background.scss';

const Background: React.FC = () => {
  return (
    <div className="TDB-OpenSourcePage__Background">
      <div className="TDB-OpenSourcePage__Background__img" />
      <div className="TDB-OpenSourcePage__Background__gradient" />
    </div>
  );
};

export default Background;
